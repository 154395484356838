import { graphql } from "gatsby"
import React from "react"
import Layout from "../src/components/layout"
import DomaineGrid from "../src/components/DomaineGrid/DomaineGrid"
import HeadingCaroussel from "../src/components/HeadingCaroussel"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import Seo from "../src/components/seo"

export default function domaineTemplate({ pageContext, data }) {
  let slideshow
  data.strapiDomaines.Slideshow
    ? (slideshow = data.strapiDomaines.Slideshow.CarrousselImages?.map(e => ({
        image: e.localFile?.childImageSharp?.gatsbyImageData,
        title: data.strapiDomaines.Domaine,
      })))
    : (slideshow = { image: null, title: data.strapiDomaines.Domaine })

  const headingData = "The Wine Selection"
  const wines = data.strapiDomaines.wines.map(e => ({
    slug: `wine/${e.slug}`,
    image: e.MainImage
      ? e.MainImage.localFile.childImageSharp.gatsbyImageData
      : null,
    title: e.title,
    id: e.id,
  }))

  wines.sort(function (a, b) {
    if (a.id < b.id) {
      return 1
    }
    if (a.id > b.id) {
      return -1
    }
    return 0
  })

  return (
    <Layout>
      <Seo
        title={data.strapiDomaines.Domaine}
        description={data.strapiDomaines.Description}
        // image={`https://brunolafonselection.com${data.strapiDomaines.MainImage.url}`} does not work
      />
      <HeadingCaroussel data={slideshow} />
      <StyledSection>
        {data.strapiDomaines.FicheIdentite ? (
          <>
            <h2 className="headingDomaine text-center">Vineyard</h2>
            <ReactMarkdown
              source={data.strapiDomaines.FicheIdentite}
              transformImageUri={uri =>
                uri.startsWith("http") ? uri : `https://lafon.coeur.pw/${uri}`
              }
              className="fiche"
              escapeHtml={false}
            />
          </>
        ) : null}
        <h2 className="headingDomaine">The Story</h2>
        <p>{data.strapiDomaines.Description}</p>
      </StyledSection>
      <DomaineGrid heading={headingData} bigData={wines} />
    </Layout>
  )
}

const StyledSection = styled.section`
  .fiche {
    text-align: left;
    margin: 0 auto;
    li {
      font-size: 20px;
      color: var(--parablack);
      font-weight: 200;
    }
    ul {
      padding-left: 0;
      list-style-position: inside;
      line-height: 1.5;
    }
    p {
      text-align: left;
    }
    strong {
      visibility: hidden;
      display: none;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 1.5px;
      font-family: "Bebas Neue";
      font-size: 28px;
      color: #424242;
    }
  }

  h2 {
    text-align: center;
    font-size: var(--coverheading);
    margin: 4rem;
  }
  margin: 2rem auto 0;
  max-width: 980px;

  p {
    margin: 0 auto;
    max-width: 600px;
    line-height: 1.5;
    column-gap: 80px;
    text-align: justify;
  }

  body {
    line-height: 1.85;
  }

  p,
  .air-p {
    font-size: 20px;
    margin-bottom: 1.3rem;
  }

  body {
    margin: 6rem auto 1rem;
    max-width: 48rem;
    text-align: center;
  }

  p,
  .fiche {
    color: var(--parablack);
    max-width: 80%;
  }
`

export const query = graphql`
  query ($id: String!) {
    strapiDomaines(id: { eq: $id }) {
      Country
      Description
      Domaine
      FicheIdentite
      MainImage {
        url
      }
      Slideshow {
        CarrousselImages {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1800, quality: 60)
            }
          }
        }
      }
      wines {
        slug
        title
        MainImage {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 200, quality: 60)
            }
          }
        }
      }
      id
    }
  }
`
