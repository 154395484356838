import styled from "styled-components"

export const StyledSection33 = styled.section`
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  gap: 1rem;
  row-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  .featured {
    grid-column: 2/3;
    grid-row: 1/-1;
  }
  h2 {
    font-size: var(--headingsec);
    max-width: 60%;
    font-weight: 400;
    margin: 0 auto;
    margin-top: 1rem;
    text-align: center;
  }

  .imagewrapper {
    width: auto;
    height: auto;
  }

  @media only screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 620px) {
    max-width: 100vw;
    grid-template-columns: repeat(2, 1fr);
    .imagewrapper {
      height: 200px !important;
    }
  }
`

export const Wrapper33 = styled.div`
  background: white;
  text-align: center;
  padding: 40px 0;
  color: #fff;
  #wines {
    font-size: 32px;
  }

  h2 {
    padding-bottom: 4rem;
  }
`
