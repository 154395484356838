import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { StyledSection33, Wrapper33 } from "./Winegrid.styles"
import { Link } from "gatsby"
import Placeholder from "../../assets/images/newpl.jpg"

const DomaineGrid = ({ heading, bigData }) => {
  return (
    <Wrapper33>
      <h2 id="wines">{heading}</h2>
      <StyledSection33>
        {bigData.map((e, index) => (
          <div key={index}>
            <Link to={`/${e.slug}/`}>
              {e.image && e.image !== "null" ? (
                <GatsbyImage
                  image={e.image}
                  className="imagewrapper"
                  alt={e.title}
                />
              ) : (
                <img
                  src={Placeholder}
                  alt="temporary placeholder"
                  style={{ height: "200px" }}
                  className="mx-auto"
                />
              )}
              <h2>{e.title}</h2>
            </Link>
          </div>
        ))}
      </StyledSection33>
    </Wrapper33>
  )
}

export default DomaineGrid
